<template>
  <div v-if="isDataLoaded">
    <!-- Controllers -->
    <v-row
      no-gutters
      color="white"
      class="d-flex justify-space-between py-4 pr-6"
    >
      <!-- Navigation -->
      <div class="d-flex flex-row align-center pl-16 my-4">
        <v-icon
          color="primary"
          size="16"
        >
          arrow_back
        </v-icon>
        <a @click="$router.push(lastRoute.name ? lastRoute : { name: 'project-players' })">
          <span class="ml-1 font-weight-bold">{{ $t('activity.editActivity.navigateBack') }}</span>
          <span>{{ lastRoute.name ? lastRoute.meta.headerTitle() : $t(`project.sections.players-${project.type}`) }}</span>
        </a>
      </div>
      <!-- Save and Update -->
      <div class="d-flex flex-row align-center pr-8 my-4">
        <v-btn
          color="primary" class="mr-3" outlined dense
          @click="$confirm($t('project.academy.users.confirmDelete'), { icon: 'warning' }).then((res) => res && deletePlayer())" v-text="$t('common.delete')"
        />
        <v-btn color="primary" type="submit" class="mr-3" dark depressed @click.prevent="update()" v-text="$t('common.save')" />
      </div>
    </v-row>
    <!-- Player Card -->
    <player-card
      :organization-id="organizationId"
      :project-id="projectId"
      :player="player"
      :teams="teams"
      :player-team-id="playerTeamId"
      :helpers="{ formatBirthdate, formatCountry }"
      @updatePlayerTeam="updatePlayerTeam"
    />
    <!-- Parent Card -->
    <parent-card
      :parents="parents"
      :helpers="{ formatBirthdate, formatCountry }"
    />
    <!-- Attendance -->
    <v-row v-if="isAcademy" class="px-16 py-4">
      <v-col>
        <h3 class="py-4" v-text="$t('project.academy.calendar.attendance')" />
        <span v-text="$t('project.academy.users.attendanceDisclaimer')" />
        <v-row class="py-8">
          <v-col cols="6" style="border-right: 1px solid rgba(0, 0, 0, 0.4);">
            <h4 v-text="$t('project.academy.calendar.matches')" />
            <v-row class="pa-4">
              <v-col cols="6">
                <v-row>
                  <v-icon small color="primary" class="mr-2">
                    check
                  </v-icon>
                  <span v-text="$t('project.academy.calendar.attended', { num: matchActivities.attended })" />
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row>
                  <v-icon small color="primary" class="mr-2">
                    calendar_today
                  </v-icon>
                  <span v-text="$t('project.academy.calendar.summoned', { num: matchActivities.summoned })" />
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <h4 v-text="$t('project.academy.calendar.trainings')" />
            <v-row class="pa-4">
              <v-col cols="6">
                <v-row>
                  <v-icon small color="primary" class="mr-2">
                    check
                  </v-icon>
                  <span v-text="$t('project.academy.calendar.attended', { num: trainingActivities.attended })" />
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row>
                  <v-icon small color="primary" class="mr-2">
                    calendar_today
                  </v-icon>
                  <span v-text="$t('project.academy.calendar.summoned', { num: trainingActivities.summoned })" />
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- Comments -->
    <v-row class="px-16 py-4">
      <v-col id="comments">
        <h3 v-text="$t('common.comments')" />
        <v-textarea
          v-model="form.comments"
          :error-messages="getFieldErrors('comments')"
          outlined
          dense
          class="mt-1"
          height="100"
          @blur="$v.form.comments.$touch()"
        />
      </v-col>
    </v-row>
    <v-row v-if="isGuild" class="px-16 py-4">
      <v-col cols="12">
        <h3 v-text="$t('cluster.tickets.playerDetail.ticketHistory')" />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="ticketsRequested" :label="$t('cluster.tickets.editMatchActivity.ticketsRequested')"
          outlined dense disabled
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="ticketsAccepted" :label="$t('cluster.tickets.assignTickets.ticketsAccepted')"
          outlined dense disabled
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="ticketsPurchased" :label="$t('cluster.tickets.assignTickets.ticketsPurchased')"
          outlined dense disabled
        />
      </v-col>
    </v-row>
    <v-row v-if="terms.length" class="px-16 py-4">
      <v-col>
        <h2 v-text="$t('common.documentWaiver')" />
        <v-btn color="primary" fab small @click="getDocumentWaiver">
          <v-icon v-text="'get_app'" />
        </v-btn>
        <span class="ml-2" v-text="$t('common.download')" />
      </v-col>
    </v-row>
    <v-row v-if="formattedForm.length" class="px-16 py-4">
      <v-col>
        <edit-user-form ref="subForm" :main-title="$t('project.academy.users.dynamicFormData')" :layout="'horizontal'" :data="formattedForm" />
      </v-col>
    </v-row>
    <!-- Payment status -->
    <!-- <template v-if="project.features.price">
      <h3 class="px-16 py-4" v-text="$t('user.paymentStatus.title')" />
      <v-row v-for="payment in filteredPayments" :key="payment.dueDate" class="px-16 py-4">
        <v-col>
          <span v-if="payment.type === 'instant'" v-text="$t('project.academy.payments.instantPayment')" />
          <span v-else-if="payment.type === 'subscription'" v-text="`${payment.installmentIndex}º ${$t('user.paymentStatus.installment')}`" />
        </v-col>
        <v-col>
          <v-chip label outlined :color="getPaymentStatusColor(payment.status)" v-text="`${payment.amount} ${project.pricing.currency}`" />
        </v-col>
        <v-divider />
      </v-row>
      <v-row>
        <v-col>
          <span v-text="$t('user.paymentStatus.totalPayment')" />
        </v-col>
        <v-col>
          <v-chip label outlined color="grey" v-text="`${paymentsAmount} ${project.pricing.currency}`" />
        </v-col>
        <v-divider />
      </v-row>
    </template> -->
    <!-- Toggle/Delete User -->
    <!-- <v-row class="px-16 py-4">
      <v-col>
        <h3 v-text="$t('project.academy.users.userStatus')" />
          TODO: CRIS:
           <v-switch
          :input-value="userIsActive"
          @change="$store.dispatch('academy/toggleUserStatus', { organizationId, projectId, playerId, value: userIsActive })"
          :label="$t(`project.academy.users.userStatus${userIsActive ? 'Active' : 'Disabled'}`)"
        />
      </v-col>
    </v-row> -->
    <v-row class="px-16 py-4">
      <v-col id="attachments">
        <h3 class="field-title" v-text="$t('common.attachments')" />
        <files-field
          v-model="form.attachments"
          class="mt-4"
          @input="$v.form.attachments.$touch()"
          @delete="$v.form.attachments.$touch()"
        />
      </v-col>
    </v-row>
    <confirm-save-dialog
      v-if="nextRoute && open"
      @close-dialog="open = false"
      @navigate="navigate"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { countries } from 'countries-list'
import formUtils from '@/utils/mixins/formUtils'
import saveDialogUtils from '@/utils/mixins/saveDialog'

export default {
  name: 'Player',
  components: {
    PlayerCard: () => import('@/modules/academy/components/PlayerCard'),
    ParentCard: () => import('@/modules/academy/components/ParentCard'),
    FilesField: () => import('@/components/formFields/FilesField'),
    ConfirmSaveDialog: () => import('@/components/ConfirmSaveDialog'),
    EditUserForm: () => import('@/modules/dynamicForm/EditUserForm'),
  },
  mixins: [formUtils, saveDialogUtils],
  beforeRouteEnter(to, from, next) { next(vm => { vm.lastRoute = from }) },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'project-players') this.$store.commit('projectUsers/updatePlayerListFilters', { selectedTeams: [], search: null })
    next()
  },
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
    playerId: { type: String, default: null },
  },
  data() {
    return {
      isDataLoaded: false,
      parents: [],
      activities: null,
      formattedForm: [],
      purchase: null,
      form: {
        comments: null,
        attachments: [],
      },
      lastRoute: {},
      ticketEnrollments: [],
    }
  },
  validations() {
    return {
      form: {
        comments: {},
        attachments: {},
      },
    }
  },
  computed: {
    ...mapGetters({ project: 'project/data' }),
    ...mapGetters({ isAcademy: 'project/isAcademy' }),
    ...mapGetters({ teams: 'academy/teams' }),
    ...mapGetters({ player: 'projectUsers/player' }),
    ...mapGetters({ membership: 'user/membership' }),
    ...mapGetters('project', ['terms', 'isGuild']),
    playerTeamId({ player }) { return player.teamId },
    userIsActive({ player, organizationId, projectId }) {
      return player
        .roles
        .byProperty[organizationId]
        .byProject[projectId]
        ?.roles
        .includes('player')
    },
    matchActivities({ activities, playerId }) {
      const allMatches = activities.filter(activity => activity.type === 'match')
      return {
        attended: allMatches.filter(activity => activity.attendanceByUserId && activity.attendanceByUserId[playerId])?.length ?? 0,
        summoned: allMatches.length,
      }
    },

    trainingActivities({ activities, playerId }) {
      const allTrainings = activities.filter(activity => activity.type === 'training')
      return {
        attended: allTrainings.filter(activity => activity.attendanceByUserId && activity.attendanceByUserId[playerId])?.length ?? 0,
        summoned: allTrainings.length,
      }
    },
    filteredPayments({ purchase }) {
      return purchase?.payments?.filter(p => ['instant', 'subscription'].includes(p.type))
    },
    paymentsAmount({ purchase }) {
      return purchase?.payments?.reduce((acc, cur) => acc + cur.amount) ?? 0
    },
    ticketsRequested: ({ ticketEnrollments, playerId }) => ticketEnrollments.filter(tE => tE.userId === playerId).length,
    ticketsAccepted: ({ ticketEnrollments, playerId }) => ticketEnrollments.filter(tE => tE.userId === playerId && tE.status === 'pendingPayment').length,
    ticketsPurchased: ({ ticketEnrollments, playerId }) => ticketEnrollments.filter(tE => tE.userId === playerId && tE.status === 'complete').length,
  },
  watch: {
    membership: {
      immediate: true,
      async handler(membership) {
        // dynamic form data
        this.formattedForm = membership?.form ? await this.parseMembership(membership) : []
        // local form data
        this.form = {
          comments: membership?.comments ?? '',
          attachments: membership?.attachments ?? [],
        }
      },
    },
  },
  async created() {
    const { organizationId, projectId, playerId } = this
    this.$store.commit('loader/show')
    await this.$store.dispatch('projectUsers/bindPlayer', playerId)
    await this.$store.dispatch('user/bindProjectMembership', { id: playerId, role: 'subscriber', projectId })
    this.ticketEnrollments = await this.$store.dispatch('guildMatches/readUserTicketEnrollments', { organizationId, projectId, userId: playerId })
    this.parents = await this.$store.dispatch('projectUsers/getParentsData', playerId) ?? []
    // read project activities, filtered by user id
    this.activities = await this.$store.dispatch('projectUsers/readActivities', { organizationId, projectId, playerId })
    if (this.project.features.price) {
      this.purchase = await this.$store.dispatch('user/readPurchase', { purchaseId: this.membership.purchaseId })
    }

    this.isDataLoaded = true
    this.$store.commit('loader/hide')
  },
  methods: {
    formatBirthdate(player) { // TODO: CRIS REFACTOR
      const { birthdate: { day, month, year } } = player
      return `${day}-${month}-${year}`
    },
    formatCountry(player) { // TODO: CRIS REFACTOR
      return countries[player.country].name
    },
    async getDocumentWaiver() {
      const { projectId, project, player } = this
      this.runAsync(async () => {
        const link = document.createElement('a')
        const url = await this.$store.dispatch('project/getDocumentWaiver', { projectId, userId: player.id })
        const blob = await new Promise(resolve => { fetch(url).then(res => resolve(res.blob())) })
        link.href = URL.createObjectURL(blob)
        link.download = `${player.firstName} ${player.lastName}_${project.name}`.replaceAll(' ', '-')
        link.click()
      }, { errorAlertCustom: this.$t('project.academy.users.errorWaiverDownload') })
    },
    async parseMembership(membership) {
      const { organizationId, projectId } = this
      const form = membership.form
      const dynamicForm = await this.$store.dispatch('dynamicForm/readForm', { organizationId, projectId })
      const { fields } = dynamicForm
      // 'fields' === array of blank fields from the form document (https://owqlo.atlassian.net/wiki/spaces/OWQLO/pages/1799979017/Dynamic+forms)
      // 'form' === object from the user.membership document { id: value, id: value, etc }
      return form ? fields.map(formValue => {
        const field = Object.entries(form).find(([key]) => key === formValue?.id)
        return formValue?.label && ({
          id: formValue?.id,
          value: field ? field[1] : null,
        })
      })?.filter(e => e) : []
    },
    async update() {
      const { playerId, projectId, form: data } = this
      if (this.$refs.subForm.form) this.$refs.subForm.$v.form.$touch()
      if (!this.isFormValid() || (this.$refs.subForm.form && !this.$refs.subForm.isFormValid())) {
        this.scrollToFirstError()
        return
      }

      const form = this.$refs.subForm.form ? this.$refs.subForm.parseForm() : null
      const dataToUpdate = { ...data, ...(form && { form }) }
      await this.runAsync(() => this.$store.dispatch(
        'user/updateMembership',
        { id: playerId, role: 'subscriber', entityType: 'project', entityId: projectId, data: dataToUpdate },
      ))
      this.userAgreedToLeave = true
    },
    async deletePlayer() {
      const { organizationId, projectId, playerId: userId } = this
      await this.runAsync(() => this.$store.dispatch('projectUsers/removePlayer', { organizationId, projectId, userId, role: 'player' }))
      this.$router.push(this.lastRoute.name ? this.lastRoute : { name: 'project-players' })
    },
    updatePlayerTeam({ oldTeamId, newTeamId }) {
      const { organizationId, projectId, player } = this
      this.runAsync(() => this.$store.dispatch('academy/updatePlayerTeam', { organizationId, projectId, userId: player.id, oldTeamId, newTeamId }))
    },
    getPaymentStatusColor: status => ({
      notRequested: 'gray',
      attempting: 'error',
      cancelled: 'error',
      complete: 'success',
    }[status]),
  },
}
</script>

<style lang="scss">

.custom .v-input__slot::before {
  border-style: none !important;
}

.custom .v-input__slot::after {
  border-style: none !important;
}
</style>
